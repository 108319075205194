import React from 'react';
import { TrackedLink as Link } from 'components/common/TrackedLink';
import { getTotalCount } from 'util/getTotalCount';
import { pluralizeForKey } from 'util/pluralizeForKey';
import { SearchContext } from 'components/layouts/research-layout/SearchContext';
import { useAssetAccess } from 'components/asset-access';
import { OtherSectionContext } from '../OtherSectionContext';
import { LoadingEllipses } from 'components/common/LoadingEllipses';
import styled from 'styled-components';

const getTotalCases = getTotalCount('cases.totalCount');
const getTotalPublications = getTotalCount('publications.totalCount');
const getTotalStatutes = getTotalCount('statutes.totalCount');
const getTotalRules = getTotalCount('courtRules.totalCount');
const getTotalPractitioner = getTotalCount('practitioner.totalCount');
const getTotalNews = getTotalCount('news.totalCount');
const getTotalCourses = getTotalCount('course.totalCount');

const pluralizeCase = pluralizeForKey('cases.totalCount', 'Case');
const pluralizeStatute = pluralizeForKey('statute.totalCount', 'Statute');
const pluralizeCourtRule = pluralizeForKey('courtrules.totalCount', 'Rule');
const pluralizePublication = pluralizeForKey(
  'publication.totalCount',
  'Result',
);
const pluralizePractitioner = pluralizeForKey(
  'practitioner.totalCount',
  'Result',
);
const pluralizeNews = pluralizeForKey('news.totalCount', 'DailyNews Article');
const pluralizeCourse = pluralizeForKey('course.totalCount', 'CLE Course');

export const OtherSections = props => {
  const { queryString, searchUrlFor } = React.useContext(SearchContext);
  const assetAccess = useAssetAccess();
  const { data, loading } = React.useContext(OtherSectionContext);

  return (
    <StyledOtherSections className="in-other-sections">
      <h3>In Other Sections</h3>
      <ul>
        {props.type !== 'DAILY_NEWS' ? (
          <li>
            <Link to={searchUrlFor('news', queryString)}>
              <b>{loading ? <LoadingEllipses /> : getTotalNews(data)}</b>{' '}
              {pluralizeNews(data)}
            </Link>
          </li>
        ) : null}
        {props.type !== 'CASE' ? (
          <li>
            <Link to={searchUrlFor('cases', queryString)}>
              <b>{loading ? <LoadingEllipses /> : getTotalCases(data)}</b>{' '}
              {pluralizeCase(data)}
            </Link>
          </li>
        ) : null}
        {props.type !== 'STATUTE' ? (
          <li>
            <Link to={searchUrlFor('statutes', queryString)}>
              <b>{loading ? <LoadingEllipses /> : getTotalStatutes(data)}</b>{' '}
              {pluralizeStatute(data)}
            </Link>
          </li>
        ) : null}
        {props.type !== 'GENERIC' ? (
          <li>
            <Link to={searchUrlFor('courtRules', queryString)}>
              <b>{loading ? <LoadingEllipses /> : getTotalRules(data)}</b>{' '}
              {pluralizeCourtRule(data)} of Court
            </Link>
          </li>
        ) : null}
        {!assetAccess?.hasOnlawBeta && props.type !== 'PUBLICATION' ? (
          <li>
            <Link to={searchUrlFor('publications', queryString)}>
              <b>
                {loading ? <LoadingEllipses /> : getTotalPublications(data)}
              </b>{' '}
              Secondary Sources {pluralizePublication(data)}
            </Link>
          </li>
        ) : null}
        {assetAccess?.hasPractitionerVisible &&
          props.type !== 'PRACTITIONER' && (
            <li>
              <Link to={searchUrlFor('practitioner', queryString)}>
                <b>
                  {loading ? <LoadingEllipses /> : getTotalPractitioner(data)}
                </b>{' '}
                Practitioner {pluralizePractitioner(data)}
              </Link>
            </li>
          )}
        {props.type !== 'COURSE' ? (
          <li>
            <Link
              to={searchUrlFor('course', {
                ...queryString,
              })}
            >
              <b>{loading ? <LoadingEllipses /> : getTotalCourses(data)}</b>{' '}
              {pluralizeCourse(data)}
            </Link>
          </li>
        ) : null}
      </ul>
    </StyledOtherSections>
  );
};

const StyledOtherSections = styled.div`
  padding: 25px 25px 0;
  h3 {
    font-size: 19px;
  }
  a {
    display: block;
  }
  ul {
    list-style-type: none;
    padding: 6px 0 0;
    font-size: 17px;
    li {
      padding: 6px 0 9px;
      &:last-child {
        padding-bottom: 3px;
      }
    }
  }
`;

export default OtherSections;
