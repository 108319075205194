import React from 'react';
import { ApolloProvider } from '@apollo/client';
import Loading from 'components/common/Loading';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import ScrollToTop from 'components/app/ScrollToTop';
import { CLELibraryLanding } from './landing';
import { AppContext } from 'components/app-context';
import styled, { createGlobalStyle } from 'styled-components';
import CLELibraryCourse from './course';
import CLELibraryPackages from './packages';
import CLELibraryPackage from './package';
import { useIntercomDefaultLauncher } from 'components/layouts/useIntercomWidget';
import { CEB_PORTAL_INTERCOM_APP_ID, CEB_PORTAL_GA_ID } from 'app-constants';
import { IntercomProvider } from 'react-use-intercom';
import TagManager from 'react-gtm-module';
import { StyleConstant } from 'util/getStyleConstant';
import { StyledGlobalApp } from 'components/app/StyledGlobalApp';
import { RecoilRoot } from 'recoil';

const AppContextProvider = ({ children }) => {
  useIntercomDefaultLauncher();

  if (CEB_PORTAL_GA_ID) {
    TagManager.initialize({
      gtmId: CEB_PORTAL_GA_ID,
    });
  }

  return <AppContext.Provider value={{}}>{children}</AppContext.Provider>;
};

export default function CLELibrary({ client }) {
  return (
    <RecoilRoot>
      <IntercomProvider
        shouldInitialize={CEB_PORTAL_INTERCOM_APP_ID !== undefined}
        appId={CEB_PORTAL_INTERCOM_APP_ID}
        autoBoot={CEB_PORTAL_INTERCOM_APP_ID !== undefined}
        autoBootProps={{ hideDefaultLauncher: true }}
      >
        <StyledContainer className="public-cle container px-3 mx-auto xl:px-0">
          <StyledContent>
            <HelmetProvider>
              <ApolloProvider client={client}>
                <Loading>
                  <Router>
                    <ScrollToTop />
                    <AppContextProvider>
                      <Loading>
                        <Routes>
                          <Route path="/" element={<CLELibraryLanding />} />
                          <Route
                            path="/course/:slug"
                            element={<CLELibraryCourse />}
                          />
                          <Route path="/collections/packages">
                            <Route path="" element={<CLELibraryPackages />} />
                            <Route
                              path=":slug"
                              element={<CLELibraryPackage />}
                            />
                          </Route>
                        </Routes>
                      </Loading>
                    </AppContextProvider>
                  </Router>
                </Loading>
              </ApolloProvider>
            </HelmetProvider>
          </StyledContent>
        </StyledContainer>
        <StyledGlobalApp />
        <GlobalStyleOverwrites />
      </IntercomProvider>
    </RecoilRoot>
  );
}

const StyledContainer = styled.div``;

const StyledContent = styled.div``;

const GlobalStyleOverwrites = createGlobalStyle`
  html, body {
    height: auto !important;
    width: auto !important;
    font-family: "BasisGrotesque", sans-serif !important;

    a:visited{
      color: inherit;
    }

    @media screen and (min-width: ${StyleConstant.BREAKPOINT_HANDHELD}) {
      height: auto !important;
      width: auto !important;
    }
  }



  #root{
    width: auto !important;
    height: auto !important;
    padding-top: 100px;
    overflow: visible;
    .container{
      margin: 0 auto;
    }
  }

  .site-footer {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .min-h-screen{
    display: flex;
    flex-direction: column;
    > *:first-child{
      flex: 0 1 auto;
    }
    > * {
      flex: 1 1 auto;
    }
    > *last-child{
      flex: 0 1 auto;
    }
  }
  .site-menu__submenu-wrap,
  .site-menu__submenu-content {
    background: white;
  }

  .site-menu__button--primary {
    &:visited{
      color: white;
    }
  }
`;
