import React from 'react';
import {
  hasPackageAccess,
  hasCoursePassAccess,
  hasCLEPassportAccess,
  getProducts,
  hasProductCode,
} from 'util/hasProductCode';
import { useAssetAccess } from 'components/asset-access';
import { WaysToAccess } from './WaysToAccess';
import { PassportAccess } from './PassportAccess';
import { PackageAccess } from './PackageAccess';
import { CoursePassAccess } from './CoursePassAccess';

export function CurrentCLEAccess() {
  const { assets } = useAssetAccess();
  const { type, hoursRemaining } = useCLEAccess();

  switch (type) {
    case 'passport':
      return <PassportAccess />;
    case 'package':
      return <PackageAccess />;
    case 'coursepass':
      const product = getProducts(assets, /09cp(06|12)0000/, false)[0];
      if (product)
        return (
          <CoursePassAccess
            hoursRemaining={hoursRemaining}
            total={parseInt(
              product.productCode.match(/09cp(06|12)0000/)[1],
              10,
            )}
          />
        );
    default:
      return <WaysToAccess />;
  }
}

export function useCLEAccess(productCodes) {
  const { assets } = useAssetAccess();

  return React.useMemo(() => {
    let coursePass = {};
    let packageAccess = {};

    const access = {
      type: hasCLEPassportAccess(assets)
        ? 'passport'
        : hasPackageAccess(assets)
          ? 'package'
          : hasCoursePassAccess(assets, false)
            ? 'coursepass'
            : false,
    };

    if (assets && access.type === 'coursepass') {
      const product = getProducts(assets, /09cp(06|12)0000/, false)[0];

      coursePass = {
        hoursRemaining: product?.quantity,
        total: parseInt(product.productCode.match(/09cp(06|12)0000/)[1], 10),
      };
    }

    if (access.type === 'package') {
      packageAccess = {
        packageAccess:
          productCodes
            ?.map(productCode => hasProductCode(assets, productCode, false))
            .filter(Boolean).length > 0,
      };
    }

    return {
      ...access,
      ...coursePass,
      ...packageAccess,
    };
  }, [assets, productCodes]);
}
